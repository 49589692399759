@use 'sass:color';

.logo {
    svg {
        path {
            fill: $accent;
        }
    }
}

header {
    position: sticky;
    position: -webkit-sticky;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    z-index: 99;
    background-color: color.scale($bgPrimary, $lightness: 0%, $alpha: -52%);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    border-bottom: 1px solid color.scale($accent, $lightness: 5%, $alpha: -92%);
    box-shadow: 0 4px 48px color.scale($accent, $alpha: -94%);

    @include breakpoint(tablet) {
        position: static;
        padding: 1.125rem 0;
    }
    &.scroll-active {
    }
    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include breakpoint(tablet) {
            align-items: center;
            display: contents;
        }
        .logo {
            #solidant-logo-icon {
                width: 279px;
                height: 51px;
                @include breakpoint(phablet) {
                    width: 190px;
                }
                @include breakpoint(mobile) {
                    width: 138px;
                    height: 25px;
                }
            }
        }
        .nav-links {
            @include breakpoint(tablet) {
                display: none;
            }
            display: flex;
            align-items: center;
            span {
                color: color.scale($accent, $lightness: 30%, $saturation: -70%);
                font-weight: 400;
                font-size: 18px;
                margin: 0 1.5rem;
                cursor: pointer;
                transition: all 0.2s ease-in-out;

                &.active,
                &:hover {
                    color: $accent;
                    background: -webkit-linear-gradient(
                        0deg,
                        color.scale(
                            $accent,
                            $lightness: -10%,
                            $saturation: 10%
                        ),
                        color.scale(
                            $secondaryColor,
                            $lightness: 0%,
                            $saturation: 0%
                        )
                    );
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    transition: all 0.2s ease-in-out;
                }
                @include breakpoint(laptop) {
                    margin: 0 0.75rem;
                }
            }
        }
    }
}
