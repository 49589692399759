section#about {
    .container {
        @include breakpoint(tablet) {
            padding-top: 4rem;
        }
        @include breakpoint(mobile) {
            padding-top: 4rem;
        }
        .title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;
            p {
                margin: 0 0 4rem;
                max-width: 600px;
                @include breakpoint(tablet) {
                    margin: 0.8rem 0;
                    // width: 430px;
                }
                @include breakpoint(phablet) {
                    // width: 340px;
                }
                @include breakpoint(mobile) {
                    // width: 300px;
                    padding: 0 1rem;
                }
            }
        }
        .features {
            margin-top: 4.75rem;
            display: flex;
            justify-content: space-between;

            @include breakpoint(tablet) {
                display: block;
                position: relative;
                margin-top: 2.625rem;
            }
            @include breakpoint(phablet) {
                margin-top: 1.4rem;
            }
            #features-main {
                height: 545px;
                width: 612px;
                @include breakpoint(laptop) {
                    margin-right: 1rem;
                    height: 430px;
                    width: 490px;
                }
                @include breakpoint(tablet) {
                    position: absolute;
                    top: -550px;
                    height: 360px;
                    width: 390px;
                    left: 50%;
                    transform: translate(-50%, 0);
                }
                @include breakpoint(phablet) {
                    height: 290px;
                    width: 320px;
                    top: -450px;
                }
                @include breakpoint(mobile) {
                    height: 223px;
                    width: 251px;
                    top: -360px;
                }
            }
        }
    }

    .features-list {
        @include breakpoint(tablet) {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        @include breakpoint(mobile) {
            padding: 0px;
        }

        .feature {
            margin-left: 2rem;
            display: grid;
            grid-template-columns: minmax(0, 128px) minmax(0, 412px);
            gap: 2rem;
            margin-bottom: 3rem;

            &:first-child {
                @include breakpoint(mobile) {
                    margin-top: 2rem;
                }
            }

            &:last-child {
                @include breakpoint(mobile) {
                    margin-bottom: 0rem;
                }
            }

            @include breakpoint(laptop) {
                gap: 1.5rem;
            }
            @include breakpoint(tablet) {
                gap: 2rem;
                margin-bottom: 3rem;
            }
            @include breakpoint(phablet) {
                gap: 1rem;
                margin-bottom: 1.5rem;
            }
            @include breakpoint(mobile) {
                margin-bottom: 2rem;
                margin-left: 0rem;
                grid-template-columns: minmax(0, 90px) minmax(0, auto);
            }
            svg {
                height: 128px;
                width: 128px;
                @include breakpoint(laptop) {
                    height: 100px;
                    width: 100px;
                }
                @include breakpoint(tablet) {
                    width: 125px;
                    height: 125px;
                }
                @include breakpoint(phablet) {
                    width: 110px;
                    height: 110px;
                }
                @include breakpoint(mobile) {
                    width: 83px;
                    height: 83px;
                }
            }
            .feature-text {
                p {
                    margin-top: 0.5rem;
                    @include breakpoint(phablet) {
                        margin-top: 0.4rem;
                    }
                    @include breakpoint(mobile) {
                        margin-top: 0rem;
                    }
                }
            }
        }
    }
}
