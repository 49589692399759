section#services {
    .container {
        .title {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            text-align: center;

            @include breakpoint(tablet) {
                text-align: center;
            }
            p {
                margin: 0 0 4rem;
                max-width: 600px;
                @include breakpoint(tablet) {
                    margin: 0.8rem 0;
                    max-width: 430px;
                }
                @include breakpoint(phablet) {
                    // width: 340px;
                }
                @include breakpoint(mobile) {
                    // width: 300px;
                }
            }
        }
        .service-list {
            display: flex;
            gap: 3rem;
            justify-content: space-between;

            @include breakpoint(laptop) {
                gap: 2rem;
            }
            @include breakpoint(tablet) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                margin-top: 2.1rem;
                gap: 1.5rem;
            }

            @include breakpoint(phablet) {
                margin-top: 1.45rem;
                gap: 2rem;
            }
            @include breakpoint(mobile) {
                gap: 1.5rem;
            }
            .card {
                text-align: center;
                border-radius: 12px;
                background-color: #030c35;
                border: 2px solid color.scale(#001a42, $lightness: -4%);
                box-shadow: 0 12px 64px color.scale($accent, $alpha: -96%);
                display: flex;
                width: 100%;

                flex-direction: column;
                justify-content: center;
                padding: 4rem 0 2rem;

                @include breakpoint(desktop) {
                }

                @include breakpoint(tablet) {
                }

                @include breakpoint(phablet) {
                }
                @include breakpoint(mobile) {
                }

                .card-icon {
                    display: flex;
                    justify-content: center;
                    svg,
                    img {
                        height: 270px;
                        mix-blend-mode: screen;
                        filter: drop-shadow(
                            0px 6px 36px color.scale($accent, $alpha: -87%)
                        );
                    }
                }
                .card-text {
                    margin: 2.5rem 2rem 0;

                    @include breakpoint(desktop) {
                        margin: 0 2rem;
                        margin-top: 1.5rem;
                    }
                    @include breakpoint(tablet) {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                        text-align: center;
                    }

                    p {
                        margin-top: 0.5rem;
                        @include breakpoint(phablet) {
                            margin-top: 0.4rem;
                        }
                        @include breakpoint(mobile) {
                            margin-top: 0rem;
                        }
                    }
                }
            }
        }
    }
}
