$fontDisplay: 'swap';
$fontPath: '../../assets/fonts/space-grotesk/';

@font-face {
    font-display: $fontDisplay;
    font-family: 'Space Grotesk Light';
    src: url($fontPath + 'spacegrotesk-light-webfont.woff2') format('woff2'),
        url($fontPath + 'spacegrotesk-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: $fontDisplay;
    font-family: 'Space Grotesk Medium';
    src: url($fontPath + 'spacegrotesk-medium-webfont.woff2') format('woff2'),
        url($fontPath + 'spacegrotesk-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: $fontDisplay;
    font-family: 'Space Grotesk Bold';
    src: url($fontPath + 'spacegrotesk-bold-webfont.woff2') format('woff2'),
        url($fontPath + 'spacegrotesk-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
