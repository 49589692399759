section#partners {
    .container {
        .title {
            text-align: center;
            margin-bottom: 2.7rem;
            @include breakpoint(mobile) {
                margin-bottom: 0rem;
            }
        }

        .project {
            margin-top: 4rem;
            padding: 4rem;
            border-radius: 12px;
            background-color: #030c35;
            border: 2px solid color.scale(#001a42, $lightness: -4%);
            box-shadow: 0 12px 64px color.scale($accent, $alpha: -96%);

            @include breakpoint(tablet) {
                margin-top: 8rem;
            }
            @include breakpoint(phablet) {
                margin-top: 6.5rem;
            }
            @include breakpoint(mobile) {
                margin-top: 2rem;
                padding: 2rem;
            }

            h2 {
                @include breakpoint(laptop) {
                }
                @include breakpoint(tablet) {
                    text-align: center;
                }
                @include breakpoint(phablet) {
                    text-align: center;
                }
                @include breakpoint(mobile) {
                    text-align: center;
                }
            }

            p {
                margin-bottom: 2rem;

                @include breakpoint(laptop) {
                    margin-bottom: 1rem;
                    text-align: center;
                }
                @include breakpoint(tablet) {
                    // margin-top: 1rem;
                }
                @include breakpoint(phablet) {
                }
                @include breakpoint(mobile) {
                    margin-bottom: 1rem;
                }
            }
            button {
                @include breakpoint(laptop) {
                    margin: auto;
                    display: block;
                }

                @include breakpoint(tablet) {
                    margin: auto;
                    display: block;
                }

                @include breakpoint(phablet) {
                    margin: auto;
                    display: block;
                }

                @include breakpoint(mobile) {
                    margin-top: 1.5rem;
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    min-width: 66%;
                }
            }
        }
    }
}

.partner {
    // border: 1px solid $accent;

    display: inline-flex;
    align-items: center;
    justify-content: center;

    @include breakpoint(mobile) {
        height: calc(48px * 2);
    }

    svg {
        max-height: 48px;

        @include breakpoint(mobile) {
            max-height: 32px;
        }

        path {
            fill: color.scale($accent, $lightness: 30%, $saturation: -70%);
            filter: drop-shadow(0px 1px 6px rgba(17, 255, 239, 0.07));
        }
    }
}
