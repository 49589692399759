@use 'sass:color';

button {
    cursor: pointer;
    font-weight: 500;
    height: 48px;
    border-radius: 8px;
    padding: 8px 24px;

    &.large {
        height: 56px;
        padding: 8px 48px;
        font-size: 120%;
    }

    &.primary {
        width: 320px;
        height: 60px;
        color: black;

        background-color: white;
        border: none;
        @include breakpoint(tablet) {
            background-color: $accent;
            color: white;
            width: 450px;
        }
        @include breakpoint(phablet) {
            width: 380px;
        }
        @include breakpoint(mobile) {
            width: 278px;
            height: 45px;
        }
    }

    &.secondary {
        box-shadow: 0 0 16px 2px color.scale($accent, $alpha: -66%) inset,
            0 0 24px 4px color.scale($accent, $alpha: -87%);
        background-color: transparent;
        outline: none;
        border: 2px solid color.scale($accent, $alpha: -27%);
        margin-left: 1.5rem;
        width: 185px;
        color: $accent;
        background: color.scale($accent, $alpha: -92%);
        background-size: 8px 8px;
        background-image: linear-gradient(
            0deg,
            $bgPrimary 20%,
            transparent 20%,
            transparent 50%,
            $bgPrimary 50%,
            $bgPrimary 70%,
            transparent 70%,
            transparent 100%
        );
        transition: all 0.2s ease-in-out;

        @include breakpoint(laptop) {
            margin-left: 0.75rem;
        }

        &:hover {
            color: color.scale($accent, $lightness: 70%);
            border: 2px solid color.scale($accent, $alpha: 0%);
            transition: all 0.2s ease-in-out;
        }
    }

    &.holo {
        color: $accent;
        position: relative;
        padding-top: 0px;
        border: 2px solid color.scale($accent, $alpha: -27%);
        box-shadow: 0 0 24px 4px color.scale($accent, $alpha: -54%) inset,
            0 0 24px 4px color.scale($accent, $alpha: -72%);
        background-color: color.scale($accent, $alpha: -92%);
        background-image: linear-gradient(
            0deg,
            $bgPrimary 20%,
            transparent 20%,
            transparent 50%,
            $bgPrimary 50%,
            $bgPrimary 70%,
            transparent 70%,
            transparent 100%
        );
        background-size: 8px 8px;
        transition: all 0.2s ease-in-out;

        &:after {
            top: -10px;
            left: -10px;
            content: '';
            background-color: color.scale($accent, $alpha: -92%);
            height: 100%;
            width: 100%;
            display: block;
            position: absolute;
            border: 2px solid color.scale($accent, $alpha: -27%);
            border-radius: 8px;
            transition: all 0.2s ease-in-out;
        }

        &:hover {
            padding-top: 8px;
            color: color.scale($accent, $lightness: 70%);
            box-shadow: 0 0 24px 4px color.scale($accent, $alpha: -27%) inset,
                0 0 24px 4px color.scale($accent, $alpha: -54%);
            transition: all 0.2s ease-in-out;

            &:after {
                top: -2px;
                left: -2px;
                background-color: color.scale($accent, $alpha: -100%);
                transition: all 0.2s ease-in-out;
            }
        }
    }
}
