// $glow-transparent: color.scale($accent, $opacity: -30%);

section#home {
    .container {
        padding-top: 4rem;
        @include breakpoint(tablet) {
            padding-top: 17rem;
        }
        @include breakpoint(phablet) {
            padding-top: 17rem;
        }
        @include breakpoint(mobile) {
            padding-top: 18rem;
        }
        .main {
            @include breakpoint(tablet) {
                display: flex;
                flex-direction: column-reverse;
            }
            .title {
                position: relative;
                z-index: 6;

                h2 {
                    color: #fff;
                }

                @include breakpoint(tablet) {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                }

                p {
                    max-width: 490px;
                    margin: 1.365rem 0 3.25rem;
                    @include breakpoint(tablet) {
                        margin-bottom: 2rem;
                    }
                    @include breakpoint(phablet) {
                        margin: 1rem 0;
                        width: 400px;
                    }
                    @include breakpoint(mobile) {
                        margin: 0.375rem 0 1rem;
                        width: 300px;
                    }
                }
            }
            .logo-showcase {
                .canvas {
                    // border: 1px solid red;
                    padding: 0;
                    margin: 0;
                    z-index: 4;
                    position: absolute;
                    top: 102px;
                    left: 0px;
                    width: 100%;
                    height: 620px;
                    z-index: 0;
                    overflow: hidden;

                    &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        width: 100%;
                        height: 256px;
                        bottom: 0px;
                        // background: -webkit-linear-gradient(
                        //     90deg,
                        //     $bgPrimary 0%,
                        //     color.scale($bgPrimary, $alpha: -100%) 90%
                        // );
                        background: linear-gradient(
                            180deg,
                            rgba(0, 4, 44, 0) 31.45%,
                            rgba(0, 4, 44, 0.77) 74.65%,
                            #00042c 100%
                        );
                        z-index: 999;
                    }

                    @include breakpoint(desktop) {
                    }
                    @include breakpoint(laptop) {
                        width: 100%;
                        height: 580px;
                        top: 102px;
                    }
                    @include breakpoint(tablet) {
                        top: 0px;
                        width: 100%;
                        height: 460px;
                    }
                    @include breakpoint(phablet) {
                        top: 0px;
                        width: 100%;
                        height: 460px;
                    }
                    @include breakpoint(mobile) {
                        top: 0px;
                        width: 100%;
                        height: 420px;
                    }
                }
                #hero-base-icon {
                    width: 897px;
                    height: 348px;
                    mix-blend-mode: color-dodge;
                    opacity: 0.4;
                    position: absolute;
                    bottom: -100px;
                    right: -60px;
                    @include breakpoint(desktop) {
                        width: 800px;
                        right: -80px;
                    }
                    @include breakpoint(laptop) {
                        right: -120px;
                    }
                    @include breakpoint(tablet) {
                        bottom: -130px;
                        left: 50%;
                        transform: translate(-60%, 0);
                    }
                    @include breakpoint(phablet) {
                        width: 550px;
                        bottom: -150px;
                    }
                    @include breakpoint(mobile) {
                        width: 380px;
                        bottom: -160px;
                    }
                }
                #hero-beam-icon {
                    z-index: 2;
                    position: absolute;
                    bottom: 30px;
                    right: -10px;
                    @include breakpoint(desktop) {
                        width: 600px;
                        right: -60px;
                    }
                    @include breakpoint(laptop) {
                        right: -100px;
                    }
                    @include breakpoint(tablet) {
                        left: 50%;
                        bottom: 0px;
                        transform: translate(-50%, 0);
                    }
                    @include breakpoint(phablet) {
                        width: 400px;
                        bottom: -70px;
                    }
                    @include breakpoint(mobile) {
                        width: 290px;
                        bottom: -105px;
                    }
                }
                #hero-dots-icon {
                    z-index: 3;
                    position: absolute;
                    right: -30px;
                    bottom: -65px;
                    @include breakpoint(desktop) {
                        width: 600px;
                        right: -40px;
                        bottom: -80px;
                    }
                    @include breakpoint(laptop) {
                        right: -90px;
                        bottom: -80px;
                    }
                    @include breakpoint(tablet) {
                        left: 50%;
                        bottom: -110px;
                        transform: translate(-52%, 0);
                    }
                    @include breakpoint(phablet) {
                        width: 400px;
                        bottom: -170px;
                    }
                    @include breakpoint(mobile) {
                        width: 290px;
                        bottom: -195px;
                    }
                }
            }
        }

        .trusted {
            position: relative;
            margin-top: 8.5rem;
            @include breakpoint(laptop) {
                margin-top: 6rem;
            }
            @include breakpoint(tablet) {
                display: flex;
                flex-direction: column;
                text-align: center;
                justify-content: center;
                align-items: center;
                margin-top: 2.125rem;
            }
            @include breakpoint(phablet) {
                margin-top: 1.5rem;
            }
            span {
                display: block;
                font-weight: 500;
                font-size: 18px;
                line-height: 22px;
                opacity: 0.5;
                margin-bottom: 1.625rem;
                @include breakpoint(tablet) {
                    margin-bottom: 1rem;
                }
                @include breakpoint(phablet) {
                    font-size: 14px;
                    margin-bottom: 0.8rem;
                }
            }
            .trusted-icons {
                display: flex;
                align-items: center;
                @include breakpoint(tablet) {
                    display: grid;
                    grid-template-columns: auto auto auto;
                    align-items: center;
                    align-items: center;
                    grid-template-rows: 1fr 1fr;
                }

                svg {
                    position: relative;

                    path {
                        fill: color.scale(
                            $accent,
                            $lightness: 30%,
                            $saturation: -70%
                        );
                        filter: drop-shadow(
                            0px 1px 6px rgba(17, 255, 239, 0.27)
                        );
                    }

                    height: 100%;
                    margin-right: 2rem;
                    @include breakpoint(laptop) {
                        margin-right: 1rem;
                    }
                    @include breakpoint(tablet) {
                        margin-right: 2rem;
                    }
                    @include breakpoint(phablet) {
                        margin-right: 1.5rem;
                    }
                    @include breakpoint(mobile) {
                        margin-right: 0.5rem;
                    }
                }
                #spool-icon,
                #faculty-icon,
                #atka-icon {
                    width: 100px;
                }
                #gamestar-icon,
                #unlimited-icon,
                #anboto-icon {
                    width: 140px;
                }
                @include breakpoint(tablet) {
                    #spool-icon,
                    #faculty-icon,
                    #atka-icon {
                        width: 120px;
                    }
                    #gamestar-icon,
                    #unlimited-icon,
                    #anboto-icon {
                        width: 160px;
                    }
                    #faculty-icon,
                    #anboto-icon,
                    #atka-icon {
                        margin-top: 2rem;
                        margin-left: 2rem;
                    }
                    #infinitecube {
                        width: 100px;
                    }
                }
                @include breakpoint(phablet) {
                    #spool-icon,
                    #faculty-icon,
                    #atka-icon {
                        width: 80px;
                    }
                    #gamestar-icon,
                    #unlimited-icon,
                    #anboto-icon,
                    #holoride {
                        width: 130px;
                    }
                    #faculty-icon,
                    #anboto-icon,
                    #atka-icon,
                    #infinitecube,
                    #holoride {
                        margin-top: 2rem;
                        margin-left: 1rem;
                    }
                }
                @include breakpoint(mobile) {
                    #spool-icon,
                    #faculty-icon,
                    #atka-icon {
                        width: 60px;
                    }
                    #gamestar-icon,
                    #unlimited-icon,
                    #anboto-icon {
                        width: 90px;
                    }
                    #faculty-icon,
                    #anboto-icon,
                    #atka-icon {
                        margin-top: 1rem;
                        margin-left: 0.75rem;
                    }
                }
            }
        }
    }
}
