body {
    font-family: $primaryFont;
    color: $primaryColor;
    line-height: 1.5;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #fff;
}

h2 {
    font-size: 50px;
    font-weight: 600;
    font-family: $titleFont;
    transition: color 1s ease;
    line-height: 61px;

    @include breakpoint(desktop) {
        font-size: 45px;
        line-height: 56px;
    }
    @include breakpoint(laptop) {
        font-size: 35px;
        line-height: 46px;
    }
    @include breakpoint(tablet) {
        font-size: 40px;
    }
    @include breakpoint(phablet) {
        font-size: 30px;
        line-height: 34px;
    }
    @include breakpoint(mobile) {
        font-size: 24px;
        line-height: 29px;
    }
}

h3 {
    font-family: $titleFont;
    font-size: 23px;
    font-weight: 600;
    transition: color 1s ease;
    line-height: 28px;
    letter-spacing: 0.025rem;
    @include breakpoint(phablet) {
        font-size: 18px;
    }
    @include breakpoint(mobile) {
        font-size: 18px;
    }
}

p {
    font-size: 16px;
    transition: color 1s ease;

    @include breakpoint(phablet) {
        font-size: 16px;
        line-height: 24px;
    }
    @include breakpoint(mobile) {
        font-size: 14px;
        line-height: 21px;
    }
}

p.lead {
    font-size: 20px;
    font-weight: 400;

    @include breakpoint(phablet) {
        font-size: 18px;
    }
    @include breakpoint(mobile) {
        font-size: 16px;
    }
}

button {
    font-family: $primaryFont;
    font-weight: 500;
    color: $primaryColor;
    font-size: 18px;
    line-height: 21.9px;
    transition: color 1s ease;
}

.highlight {
    color: $accent;
    text-shadow: 0px 2px 24px color.scale($accent, $alpha: -70%);
    background: -webkit-linear-gradient(
        0deg,
        color.scale($accent, $lightness: -10%, $saturation: 10%),
        color.scale($secondaryColor, $lightness: 0%, $saturation: 0%)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.bm-p-lighter {
    mix-blend-mode: plus-lighter;
}
