html,
body {
    background-color: $bgPrimary;
}

#root {
    // overflow-x: hidden;
}

section {
    display: flex;
    justify-content: center;
    .container {
        margin: 4rem 0;
        @include breakpoint(tablet) {
            margin: 3rem 0;
        }
        @include breakpoint(phablet) {
            margin: 1.5rem 0;
        }
    }
}

.container {
    max-width: 1440px;
    width: 100%;
    padding: 0 1.5rem;

    @media (min-width: $desktop + 1) {
        padding: 0 2rem;
    }

    @media (min-width: $laptop + 1) and (max-width: $desktop) {
        padding: 0 2rem;
    }

    @media (min-width: $tablet + 1) and (max-width: $laptop) {
        padding: 0 2rem;
    }

    @include breakpoint(mobile) {
        padding: 0 1.5rem;
    }
}
