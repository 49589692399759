@mixin breakpoint($point) {
    @if $point == desktop {
        @media (max-width: $desktop) {
            @content;
        }
    } @else if $point == laptop {
        @media (max-width: $laptop) {
            @content;
        }
    } @else if $point == tablet {
        @media (max-width: $tablet) {
            @content;
        }
    } @else if $point == phablet {
        @media (max-width: $phablet) {
            @content;
        }
    } @else if $point == mobile {
        @media (max-width: $mobile) {
            @content;
        }
    } @else {
        @media ($point) {
            @content;
        }
    }
}
