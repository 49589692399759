footer {
    display: flex;
    justify-content: center;
    .container {
        margin: 2rem 0 5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @include breakpoint(phablet) {
            margin: 2rem 0;
        }
        @include breakpoint(mobile) {
            margin-bottom: 1.5rem;
        }
        #solidant-logo-icon {
            @include breakpoint(phablet) {
                width: 250px;
                height: 100%;
            }
            @include breakpoint(mobile) {
                width: 161.73px;
            }
        }
        span {
            margin-top: 1.375rem;
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            @include breakpoint(phablet) {
                font-size: 13px;
                margin-top: 1rem;
            }
            @include breakpoint(mobile) {
                font-size: 8px;
                margin-top: 0.6rem;
            }
        }
    }
}
