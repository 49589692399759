.flex-container {
    box-sizing: border-box;
    margin: 0 auto;
    padding: 0 1em;

    @media (min-width: $desktop) {
        max-width: $desktop;
    }

    @media (min-width: $laptop) {
        max-width: $laptop;
    }

    @media (min-width: $tablet) {
        max-width: $tablet;
    }

    @media (min-width: $phablet) {
        max-width: $phablet;
    }

    @media (min-width: $mobile) {
        max-width: $mobile;
    }
}

.flex-row {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;

    &.align-v-center {
        align-items: center;
    }

    &.align-h-center {
        justify-content: center;
    }

    &.align-space-between {
        justify-content: space-between;
    }

    &.align-v-bottom {
        align-items: flex-end;
    }

    &.align-h-right {
        justify-content: flex-end;
    }

    &.reverse {
        flex-direction: row-reverse;
    }
}

@for $i from 1 through 12 {
    .col-xs-#{$i},
    .col-sm-#{$i},
    .col-md-#{$i},
    .col-lg-#{$i},
    .col-xl-#{$i} {
        box-sizing: border-box;
        padding: 1em;
    }

    .col-xs-#{$i} {
        @media (max-width: $mobile) {
            width: calc(100% * (#{$i} / 12));
        }
    }

    .col-sm-#{$i} {
        @media (min-width: $mobile + 1) and (max-width: $phablet) {
            width: calc(100% * (#{$i} / 12));
        }
    }

    .col-md-#{$i} {
        @media (min-width: $phablet + 1) and (max-width: $tablet) {
            width: calc(100% * (#{$i} / 12));
        }
    }

    .col-lg-#{$i} {
        @media (min-width: $tablet + 1) and (max-width: $laptop) {
            width: calc(100% * (#{$i} / 12));
        }
    }

    .col-xl-#{$i} {
        @media (min-width: $laptop + 1) and (max-width: $desktop) {
            width: calc(100% * (#{$i} / 12));
        }
    }

    .col-xxl-#{$i} {
        @media (min-width: $desktop + 1) {
            width: calc(100% * (#{$i} / 12));
        }
    }
}
