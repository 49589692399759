// Colors
$primaryColor: #bee9e6;
$secondaryColor: #4fc1f3;
$accent: #11ffef;
$bgPrimary: #00042c;

// Fonts
$titleFont: 'Space Grotesk Bold', sans-serif;
$primaryFont: 'Space Grotesk Medium', sans-serif;

// Grid
$desktop: 1440px;
$laptop: 1120px;
$tablet: 900px;
$phablet: 768px;
$mobile: 480px;
