.left-lights {
    &:before,
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        filter: blur(120px);
        will-change: filter;
        mix-blend-mode: color-dodge;
        z-index: 0;
    }

    &:before {
        width: 25%;
        height: 900px;
        left: -12.5%;
        // top: calc(50% - 900px / 2 + 151px);
        top: 100px;
        opacity: 0.15;
        background: linear-gradient(
            180deg,
            $accent,
            color.scale($accent, $alpha: -52%)
        );
        transform: rotate(-15deg);
        border-bottom-left-radius: 25% 25%;
        border-bottom-right-radius: 25% 25%;
        border-top-left-radius: 100% 100%;
        border-top-right-radius: 100% 100%;
    }

    &:after {
        width: 40%;
        height: 422px;
        left: 0;
        top: calc(50% - 422px / 2 + 298px);
        opacity: 0.5;
        background: linear-gradient(
            180deg,
            rgba(29, 92, 162, 0.2),
            rgba(42, 138, 246, 0.4)
        );
    }
}

.right-lights {
    &:before,
    &:after {
        content: '';
        position: absolute;
        pointer-events: none;
        filter: blur(128px);
        will-change: filter;
        mix-blend-mode: color-dodge;
    }

    &:before {
        z-index: 200;
        width: 25%;
        height: 900px;
        right: -12.5%;
        // top: calc(50% - 900px / 2 + 151px);
        top: 100px;
        background-image: linear-gradient(
            180deg,
            color.scale(#11d4ff, $lightness: -40%, $alpha: -40%),
            #11d4ff
        );
        transform: rotate(15deg);
        border-bottom-left-radius: 25% 25%;
        border-bottom-right-radius: 25% 25%;
        border-top-left-radius: 100% 100%;
        border-top-right-radius: 100% 100%;
        opacity: 0.15;
        overflow: hidden;
    }

    &:after {
        width: 40%;
        height: 422px;
        right: 0;
        top: calc(50% - 422px / 2 + 298px);
        opacity: 0.25;
        background: linear-gradient(
            180deg,
            color.scale(#11d4ff, $lightness: -40%, $alpha: -40%),
            #11d4ff
        );
        transform: matrix(-1, 0, 0, 1, 0, 0);
    }
}

.spot-light {
    position: absolute;
    height: 196px;
    width: 1024px;
    opacity: 0.2;
    background: #00f3f9;
    border-radius: 50%;
    filter: blur(102px);
    display: block;

    &.left {
        transform: rotate(11deg);
        left: -42.5%;
        top: -150%;
    }

    @include breakpoint(laptop) {
        display: block;
    }

    @include breakpoint(tablet) {
        display: block;
    }

    @include breakpoint(phablet) {
        display: none;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}
